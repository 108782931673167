import React, { useEffect, Fragment, useState } from 'react';
import {
  TextField,
  Chip,
  Checkbox,
  InputAdornment,
  Badge,
  Popover,
  FormLabel,
  Typography, ListItem,
} from '@mui/material';
import { isEmpty, uniq, uniqBy } from 'lodash';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';


const styles = commonResourceStyle();

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    margin: '10px 0',
    position: 'relative',
    marginTop: 5,

    [theme && theme.breakpoints.up('md')]: {
      margin: props => (props.form ? '0' : '20px 0px 20px 20px'),
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '6px 30px 7px 5px !important',
    },

    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.87)',
        opacity: 1,
        '&:focus': {
          opacity: 0.5,
        },
      },
    },

    '& .Mui-focused': {
      '& .MuiAutocomplete-input': {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          opacity: 0.5,
        },
      },
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },

    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: '100px',
      marginLeft: '5px',
      marginBottom: '1px',
    },

    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 12px',
    },

    '& .MuiBadge-badge': {
      height: '24px',
    },
  },
}));

const filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => option.name,
});

export default function PracticeSelection({
  value,
  multiple = false,
  title,
  items,
  fastpassData,
  form,
  handleStateChange,
  label,
  helpMessage,
  disabled,
  lettersTypedToShowOptions = 3,
}) {
  const classes = useStyles({ form });
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [help, setHelp] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isEmpty(inputValue) || (!isEmpty(inputValue) && inputValue.length <= lettersTypedToShowOptions)) {
      setOptions([]);
      setOpen(false);
    } else {
      const filteredPractices = items.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()));
      setOptions(filteredPractices);
    }
  }, [inputValue]);

  const handleClickHelp = (event) => {
    setHelp(event.currentTarget);
  };

  return (
    <div>
      {label && (
        <FormLabel style={styles.formControlLabel}>
          {label}
          {helpMessage && (
            <React.Fragment>
              <HelpOutlineIcon
                style={styles.helpOutlineIcon}
                onClick={handleClickHelp}
              />
              <Popover
                open={Boolean(help)}
                anchorEl={help}
                onClick={() => { setHelp(null); }}
              >
                <Typography style={styles.helpContents} sx={{ p: 2 }}>{helpMessage}</Typography>
              </Popover>
            </React.Fragment>
          )}
        </FormLabel>
      )}
      <Autocomplete
        id="practices-autocomplete"
        classes={classes}
        sx={{
          '& .MuiAutocomplete-tag': {
            maxWidth: '100px',
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(inputValue.length >= lettersTypedToShowOptions);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        multiple={multiple}
        getOptionLabel={option => (option.name ? option.name : '')}
        options={options}
        popupIcon={null}
        disabled={disabled}
        onChange={(event, newValue, reason, details) => {
          handleStateChange(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            id="select-multiple-checkbox"
            variant="outlined"
            placeholder={isEmpty(value) ? title : ''}
            onFocus={e => e.target.placeholder = 'Type letters'}
            onBlur={e => e.target.placeholder = isEmpty(value) ? title : ''}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const isSeleted = fastpassData && fastpassData.find(x => x.practice_id === option.id);
          return (
            <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
              <Checkbox checked={!!isSeleted || selected || ((option.key || option.name) == value)} disabled={isSeleted} key={`${option.id}_checkbox`} />
                {' '}
                {option.key || option.name || ''}
            </ListItem>
          );
        }}
        isOptionEqualToValue={(option, value) => !isEmpty(value) && option.id === value.id}
      />
    </div>
  );
}
