import React, { Component, Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { FormLabel, Popper, ListItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ExpandMore } from '@mui/icons-material';

import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';

class SingleSearchableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: null,
      helpMsg: null,
    };
  }

  render() {
    const {
      value,
      title,
      items,
      classes,
      disabled,
      helpMessage,
      isRequired,
      onChange,
      autocompleteOnly,
      theme,
    } = this.props;
    const { help, helpMsg } = this.state;
    const displayError = isRequired && !value;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <FormControl fullWidth style={autocompleteOnly ? { marginTop: '0' } : stylesWithTheme.formControl}>
        <div style={autocompleteOnly ? { display: 'none' } : stylesWithTheme.formControlLabelContainer}>
          <FormLabel sx={stylesWithTheme.formControlLabel}>
            {title}
            {helpMessage && (
              <HelpOutlineIcon
                style={stylesWithTheme.helpOutlineIcon}
                onClick={e => this.setState({
                  help: e.target,
                  helpMsg: helpMessage,
                })}
              />
            )}
          </FormLabel>
          {displayError && (
            <FormLabel sx={stylesWithTheme.formControlLabelWarnText}>This field is required</FormLabel>
          )}
        </div>
        <Autocomplete
          disabled={disabled}
          style={{
            width: 'auto',
            minWidth: 'max-content',
            cursor: 'pointer',
          }}
          options={items}
          PopperComponent={params => (
            <Popper
              {...params}
              placement='bottom-start'
              modifiers={[{ flip: { enabled: false } }]}
            />
          )}
          autoHighlight
          value={items.find(it => (it.id || it.key || it.name || it.title || it) == value) || ''}
          getOptionLabel={option => option.name || option.title || ''}
          popupIcon={(
            <ExpandMore
              style={{
                color: disabled ? '' : 'black',
                fontSize: '19px',
                marginRight: '5px',
                marginTop: '4px',
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
              <Checkbox checked={selected || ((option.id || option.key || option.name || option.title || option) == value)} key={`${option.id}_checkbox`} />
                {' '}
                {option.name || option.title || option.key || ''}
            </ListItem>
          )}
          onChange={(event, newValue) => {
            onChange(newValue && (newValue.id || newValue.key || newValue.name || newValue.title || newValue));
          }}
          renderInput={params => (
            <TextField
              {...params}
              style={{ width: '100%' }}
              label=""
              variant="outlined"
              placeholder="Please Select"
              error={displayError}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                style: stylesWithTheme.searchableSingleSelect,
              }}
            />
          )}
        />
        <Popover
          open={!!help}
          anchorEl={help}
          onClose={e => this.setState({ help: null })}
        >
          <Paper sx={stylesWithTheme.helpPopover}>
            <Typography sx={stylesWithTheme.helpPopoverText}>{helpMsg}</Typography>
          </Paper>
        </Popover>
      </FormControl>
    );
  }
}

SingleSearchableSelect.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  helpMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};

SingleSearchableSelect.defaultProps = {
  isRequired: false,
};

export default withStyles(commonResourceStyle, { withTheme: true })(SingleSearchableSelect);
