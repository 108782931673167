import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  ButtonGroup,
  Button,
  IconButton,
  Icon,
  Badge,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

import Circle from '../Datepicker/Circle';
import { dateTimeFormat } from '../Datepicker/dateUtils';
import {
  dialogCloseButtonStyle,
  dialogTitleContainerStyle,
  dialogFormRowStyle,
  green, orange, navy, gray,
} from '../../css/style';
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const MultipleNeedsDialogStyles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  dateContainer: {
    '& .MuiBadge-badge': {
      height: '40px',
      width: '40px',
      borderRadius: '50%',
      fontSize: '14px',
      fontWeight: 'bold',
    },

    '& .MuiBadge-colorPrimary': {
      color: '#fff',
      backgroundColor: `${green}`,
    },

    '& .MuiSvgIcon-colorPrimary': {
      color: `${green}`,
    },

    '& .MuiBadge-colorError': {
      color: '#fff',
      backgroundColor: `${orange}`,
    },

    '& .MuiSvgIcon-colorError': {
      color: `${orange}`,
    },

    '& .MuiButtonGroup-grouped': {
      minWidth: '24px',
    },
    '& .MuiButton-outlined': {
      padding: 5,
      border: 'none',
      width: '40px',

      '&:hover': {
        outline: 'none',
        border: 'none',
      },
    },
  },
  pendingContainer: {
    '& .MuiBadge-badge': {
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '-10px',
    },

    '& .MuiBadge-colorPrimary': {
      color: `${green}`,
      backgroundColor: 'transparent',
    },

    '& .MuiSvgIcon-colorPrimary': {
      color: `${green}`,
    },

    '& .MuiBadge-colorError': {
      backgroundColor: 'transparent',
    },

    '& .MuiSvgIcon-colorError': {
      color: `${orange}`,
    },

  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme.palette.grey[500],
  },
  icon: {
    height: '30px',
    width: '30px',
    padding: '12px',
    textAlign: 'center',
    margin: '20px 0',
    border: 'solid 3px #4bbdad',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4bbdad',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
  },
  dialogContent: {
    height: '130px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 25px',
  },
  button: {
    backgroundColor: '#243060',
    color: 'white',
    width: '100%',
    margin: 0,
    padding: '14px',
    '&:hover': {
      backgroundColor: '#243060',
      color: '#ffffff',
    },
  },
  typographyName: {
    marginTop: '15px',
    fontWeight: 'bold',
  },
  formRow: {
    ...dialogFormRowStyle,
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  formLabel: {
    '& label': {
      lineHeight: 'normal',
      padding: '14px 0',
    },
  },
  totalLabel: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0d256e',
    minWidth: '100px',
  },
  day: {
    margin: `0 ${theme.spacing(1)}`,
    fontFamily: 'Asap',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  pendingCircle: {
    marginTop: '-20px',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000000',
  },
  pendingToAvaiArrow: {
    transform: 'rotate(-90deg)',
  },
  pendingToTakenArrow: {
    transform: 'rotate(90deg)',
  },
});

class MultipleNeedsDialog extends Component {
  constructor(props) {
    super(props);
    const { needsOnSelectedDay } = props;
    this.state = {
      dayKey: needsOnSelectedDay.dayKey,
      available: needsOnSelectedDay.available,
      taken: needsOnSelectedDay.taken,
      selectedDate: needsOnSelectedDay.selectedDate,
      pending: needsOnSelectedDay.pending,
      initTotalNeeds: needsOnSelectedDay.available + needsOnSelectedDay.taken + needsOnSelectedDay.pending,
      pendingToAvai: 0,
      pendingToTaken: 0,
    };
  }

  handleSave() {
    const { handleCallback,needsOnSelectedDay } = this.props;
    const {
      dayKey,
      available,
      taken,
      pending,
      selectedDate,
      pendingToAvai,
      pendingToTaken,
    } = this.state;
    handleCallback({
      dayKey,
      available: Number(available),
      taken: Number(taken),
      pending: Number(pending),
      selectedDate,
      pendingToAvai,
      pendingToTaken,
      isChild: needsOnSelectedDay.isChild,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { needsOnSelectedDay } = nextProps;
    const {
      dayKey,
      available,
      taken,
      pending,
      selectedDate,
    } = needsOnSelectedDay;

    if (selectedDate && prevState.selectedDate !== selectedDate) {
      return {
        dayKey,
        available: available + taken + pending < 1 ? 1 : available,
        taken: available + taken + pending < 1 ? 0 : taken,
        pending: pending || 0,
        initTotalNeeds: available + taken + pending,
        selectedDate,
      };
    }
    return null;
  }

  render() {
    const {
      classes,
      handleCallback,
      open,
      needsOnSelectedDay,
      theme,
      coronadoErDayColor,
    } = this.props;
    const {
      dayKey,
      available,
      taken,
      pending,
      initTotalNeeds,
      pendingToAvai,
      pendingToTaken,
    } = this.state;
    const dayInNumeric = new dateTimeFormat('en-US', {
      day: 'numeric',
    });
    if (!needsOnSelectedDay || !needsOnSelectedDay.selectedDate) return null;
    const stylesWithTheme = MultipleNeedsDialogStyles(theme);
    const title = needsOnSelectedDay?.isChild ? 'Available Coronado' : 'Available All';
    const disableIncrementAvailable = needsOnSelectedDay?.maxAvailable >= 0 && available >= needsOnSelectedDay.maxAvailable;
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            maxWidth: '420px',
          },
        }}
        open={open}
        onClose={() => handleCallback('close')}
      >
        <DialogTitle>
          <div className={classes.titleContainer}>
            <Typography color="primary" sx={{ ...stylesWithTheme.title, color: coronadoErDayColor }}>
              {title}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleCallback('close')}
          >
            <Close/>
          </IconButton>
        </DialogTitle>

        <DialogContent sx={stylesWithTheme.dialogContent} style={{ paddingTop: '16px' }}>
          <div className={classes.titleContainer}>
            <div className={classes.dateContainer}>
              <Badge
                key={`dialog_avai_icon_${dayKey}`}
                overlap="circular"
                badgeContent={(
                  <ButtonGroup
                    style={{
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginLeft: '-40px',
                    }}
                  >
                    <ButtonGroup
                      orientation="vertical"
                    >
                      <IconButton
                        aria-label="increase available"
                        key="increase_available"
                        size="small"
                        onClick={() => (!disableIncrementAvailable && this.setState({ available: available + 1 }))}
                        sx={{
                          '& .MuiSvgIcon-colorPrimary': {
                            color: disableIncrementAvailable ? gray : (coronadoErDayColor || `${green}`),
                          },
                        }}
                      >
                        {disableIncrementAvailable
                          ? (<svg className="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeLarge"
                            focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropUpIcon">
                            <path d="m7 14 5-5 5 5z" fill="none" stroke="gray" stroke-width="0.5"></path>
                          </svg>)
                          : (<ArrowDropUpIcon fontSize="large" color='primary'/>)
                        }

                      </IconButton>
                      <IconButton
                        aria-label="decrease available"
                        key="decrease_available"
                        size="small"
                        onClick={() => {
                          if (available > 0) {
                            if (needsOnSelectedDay.initPending && (available - 1 + taken + pending < initTotalNeeds)) {
                              this.setState({ pending: needsOnSelectedDay.initPending }); // Refresh pending as initial value
                            }
                            this.setState({ available: available - 1 });
                          }
                        }}
                        sx={{
                          '& .MuiSvgIcon-colorPrimary': {
                            color: coronadoErDayColor || `${green}`,
                          },
                        }}
                      >
                        <ArrowDropDownIcon fontSize="large" color='primary' />
                      </IconButton>
                    </ButtonGroup>
                    <Button style={{ marginLeft: '-5px' }}>{available}</Button>

                  </ButtonGroup>
                )}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                size="large"
                sx={{
                  '& .MuiBadge-colorPrimary': {
                    backgroundColor: coronadoErDayColor || `${green}`,
                  },
                }}
              >
                <Badge
                  key={`dialog_taken_icon_${dayKey}`}
                  overlap="circular"
                  badgeContent={(
                    <ButtonGroup
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginRight: '-40px',
                      }}
                    >
                      <Button style={{ marginLeft: '5px' }}>{taken}</Button>
                      <ButtonGroup
                        orientation="vertical"
                      >
                        <IconButton
                          aria-label="increase taken"
                          key="increase_taken"
                          size="small"
                          onClick={() => (this.setState({ taken: taken + 1 }))}
                          sx={{
                            '& .MuiSvgIcon-colorPrimary': {
                              color: navy,
                            },
                          }}
                        >
                          <ArrowDropUpIcon fontSize="large" color='primary' />
                        </IconButton>
                        <IconButton
                          aria-label="decrease taken"
                          key="decrease_taken"
                          size="small"
                          onClick={() => {
                            if (taken > 0) {
                              // Refresh pending as initial value
                              if (needsOnSelectedDay.initPending && (taken - 1 + available + pending < initTotalNeeds)) {
                                this.setState({ pending: needsOnSelectedDay.initPending });
                              }
                              this.setState({ taken: taken - 1 });
                            }
                          }}
                          sx={{
                            '& .MuiSvgIcon-colorPrimary': {
                              color: navy,
                            },
                          }}
                        >
                          <ArrowDropDownIcon fontSize="large" color='primary' />
                        </IconButton>
                      </ButtonGroup>
                  </ButtonGroup>
                  )}
                  color='secondary'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiBadge-colorSecondary': {
                      backgroundColor: navy,
                    },
                  }}
                >
                  <Circle
                    key={`dialog_day-${dayKey}`}
                    label={dayInNumeric.format(needsOnSelectedDay.selectedDate)}
                    hasMultipleNeeds
                    hasMultipleTakensOnly={Number(available) === 0 && Number(taken) >= 1}
                    style={stylesWithTheme.day}
                    enlarged
                    coronadoErDayColor={coronadoErDayColor}
                  />
                </Badge>
              </Badge>
            </div>
            {needsOnSelectedDay.initPending > 0 && (
              <div className={classes.pendingContainer}>
                <Badge
                  key={`dialog_pending_to_avai_${dayKey}`}
                  overlap="circular"
                  badgeContent={(
                    <Tooltip title="Set pending to available" placement='top'>
                      <IconButton
                        aria-label="increase pending available"
                        key="increase_pending_available"
                        size="small"
                        onClick={() => {
                          if (pending > 0) {
                            this.setState({ available: available + 1, pending: pending - 1, pendingToAvai: pendingToAvai + 1 });
                          }
                        }}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginLeft: '-40px',
                        }}
                      >
                        <ArrowDropUpIcon fontSize="large" color="primary" sx={stylesWithTheme.pendingToAvaiArrow} />
                      </IconButton>
                    </Tooltip>
                  )}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Badge
                    key={`dialog_pending_to_taken_${dayKey}`}
                    overlap="circular"
                    badgeContent={(
                      <Tooltip title="Set pending to taken" placement='top'>
                        <IconButton
                          aria-label="increase pending taken"
                          key="increase_pending_taken"
                          size="small"
                          onClick={() => {
                            if (pending > 0) {
                              this.setState({ taken: taken + 1, pending: pending - 1, pendingToTaken: pendingToTaken + 1 });
                            }
                          }}
                          style={{
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: '-40px',
                          }}
                        >
                          <ArrowDropUpIcon fontSize="large" color='error' sx={stylesWithTheme.pendingToTakenArrow} />
                        </IconButton>
                      </Tooltip>
                    )}
                    color='error'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Circle
                      key={`dialog_pending-${dayKey}`}
                      label={pending}
                      isPending
                      style={stylesWithTheme.pendingCircle}
                      onCheck={(e) => {
                        // Refresh pending as initial value
                        this.setState({ pending: needsOnSelectedDay.initPending, pendingToAvai: 0, pendingToTaken: 0 });
                      }}
                      tooltipTitle='Click to reset pending'
                    />
                  </Badge>
                </Badge>
              </div>
            )}
          </div>
          <div style={stylesWithTheme.formRow}>
            {/*<Typography sx={stylesWithTheme.totalLabel}>{`Total needs: ${Number(available) + Number(taken) + Number(pending)}`}</Typography>*/}
          </div>
        </DialogContent>
        <DialogActions sx={stylesWithTheme.dialogActions}>
          <Button
            sx={stylesWithTheme.button}
            onClick={() => this.handleSave()}
            color="primary"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


MultipleNeedsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(MultipleNeedsDialogStyles, { withTheme: true })(MultipleNeedsDialog);
