import {
  Badge,
  FormControlLabel,
  InputAdornment,
  Paper,
  Select,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  CircularProgress,
  ButtonBase,
  Drawer,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual, uniqBy } from 'lodash';
import PlaceIcon from '@mui/icons-material/Place';
import {
  COLORS,
  EMPLOYMENT_TYPE_TITLES,
  STAFF_WB_LOCATION_OPTIONS,
  TALENT_ROLES_CONFIG,
} from '../../config/enums';
import {
  AnimalTypeSlider,
  iconGray,
  orange,
  textColor,
} from '../../css/style';

import { SavedSearchFormStyle } from '../../css/savedSearchFormStyle';

import { LocationSearchInput } from './LocationSearchInput';
import { getEmploymentTypes, pruneMobileInput } from '../../utils/Functions';
import PracticeAutocomplete from './PracticeAutocomplete';
import { IMG_STORAGE_LINK } from '../constants/Constants';
import SearchPracticeByMap from './SearchPracticeByMap';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const PrettoSlider = withStyles(AnimalTypeSlider)(Slider);
const allTypesObj = { type: 'all', subKey: 'all', subType: 'all' };
const textfieldInput = { height: '35px', fontSize: '16px', fontWeight: 400 };

export class SavedSearchForm extends Component {
  constructor(props) {
    super(props);
    const { colorIndex, user, resources, talent, talentProfileItems, practices } = props;
    const {
      criterias,
    } = resources;
    const savedSearch = props.savedSearch || { hasEmail: true };
    const savedSearchPractices = !isEmpty(savedSearch.practices) ? [...savedSearch.practices] : [];
    const selectedPractices = !isEmpty(savedSearchPractices) ? savedSearchPractices.filter(p => p.active === true && !p.deletedAt) : [];
    const inactiveSelectedPractices = !isEmpty(savedSearchPractices) ? savedSearchPractices.filter(p => p.active === false || p.deletedAt) : [];

    const talentType = talent.type; // eg: 'vet-tech-or-assistant'
    const talentTypeSubKey = talentType ? TALENT_ROLES_CONFIG[talentType].subKey : null; // eg: 'vet-sub-category'
    const subTypeCri = talentTypeSubKey && criterias && criterias.find(c => c.name === talentTypeSubKey);
    const talentSubTypeItem = (subTypeCri && talentProfileItems.find(item => item.criteria_id === subTypeCri.id)) || {};
    const talentSubType = talentTypeSubKey === 'speciality' ? talentSubTypeItem.str2Value : talentSubTypeItem.strValue; // eg: 'customer-service'/ 'vet-assistant'

    let extraRoles = [];
    const talentExtraTypes = !isEmpty(talent.extraTypes) ? JSON.parse(talent.extraTypes) : []; // eg: 'vet-tech-or-assistant'
    if (!isEmpty(talentExtraTypes) && talentProfileItems) {
      const extraRolesCriteria = (criterias && criterias.find(c => c.name === 'extra-roles')) || {};
      const extraRolesItem = talentProfileItems.find(item => item.criteria_id === extraRolesCriteria.id);

      if (extraRolesItem && !isEmpty(extraRolesItem.longTextValue)) {
        const extraRolesValues = JSON.parse(extraRolesItem.longTextValue) || [];
        extraRolesValues.forEach((role) => {
          const exSubKey = TALENT_ROLES_CONFIG[role.talentType].subKey;
          let subTypeObj = (exSubKey && role.profileItems.find(r => r.criteria === exSubKey)) || {};

          extraRoles.push({
            type: role.talentType,
            subKey: exSubKey,
            subType: exSubKey === 'speciality' ? subTypeObj.str2Value : subTypeObj.strValue,
          });
        });
      }
    }

    this.state = {
      help: null,
      helpMsg: null,
      color: savedSearch.color || COLORS[colorIndex] || COLORS[0],
      hasEmail: savedSearch.hasEmail,
      title: savedSearch.title || '',
      employmentType: savedSearch.positionType || '',
      range: savedSearch.range || 20,
      hasSms: savedSearch.hasSms || false,
      city: savedSearch.city || '',
      state: savedSearch.state || '',
      postalCode: savedSearch.postalCode || '',
      lat: savedSearch.lat || '',
      lng: savedSearch.lng || '',
      address: savedSearch.address || '',
      isDefaultBulletin: savedSearch.isDefaultBulletin || false,
      isDefaultRelief: savedSearch.isDefaultRelief || false,
      isDefaultReliefHome: savedSearch.isDefaultReliefHome || false,
      notificationFrequency: savedSearch.notificationFrequency || 'DAILY',
      selectedPractices,
      inactiveSelectedPractices,
      mobile: '',
      updated: false,
      selectPracticeEnabled: user.privateAccess && (user.privateAccess.indexOf('HQ Admin') >= 0 || user.privateAccess.indexOf('Private User') >= 0),
      staffLocationType: !isEmpty(savedSearch.practices) ? 'SELECT_PRACTICES' : 'WHOLE_STATE',
      practicesInState: savedSearch.state && practices ? practices.filter(p => p.state === savedSearch.state && (p.active === true && !p.deletedAt)) : [],
      talentType,
      selectedRole: savedSearch.talentRole || allTypesObj,
      extraRoles,
      roles: [{
        type: talentType,
        subKey: talentTypeSubKey,
        subType: talentSubType,
      }, ...extraRoles],
      isSaving: false,
      isDeleting: false,
      isOpenSearchPractice: false,
    };
  }

  setData = () => {
    const { resources, talentProfileItems, talent, practices } = this.props;
    const {
      criterias,
    } = resources;

    const talentType = talent.type; // eg: 'vet-tech-or-assistant'
    const talentTypeSubKey = talentType ? TALENT_ROLES_CONFIG[talentType].subKey : null; // eg: 'vet-sub-category'
    const subTypeCri = talentTypeSubKey && criterias && criterias.find(c => c.name === talentTypeSubKey);
    const talentSubTypeItem = (subTypeCri && talentProfileItems.find(item => item.criteria_id === subTypeCri.id)) || {};
    const talentSubType = talentTypeSubKey === 'speciality' ? talentSubTypeItem.str2Value : talentSubTypeItem.strValue; // eg: 'customer-service'/ 'vet-assistant'

    let extraRoles = [];
    const talentExtraTypes = !isEmpty(talent.extraTypes) ? JSON.parse(talent.extraTypes) : []; // eg: 'vet-tech-or-assistant'
    if (!isEmpty(talentExtraTypes) && talentProfileItems) {
      const extraRolesCriteria = (criterias && criterias.find(c => c.name === 'extra-roles')) || {};
      const extraRolesItem = talentProfileItems.find(item => item.criteria_id === extraRolesCriteria.id);

      if (extraRolesItem && !isEmpty(extraRolesItem.longTextValue)) {
        const extraRolesValues = JSON.parse(extraRolesItem.longTextValue) || [];
        extraRolesValues.forEach((role) => {
          const exSubKey = TALENT_ROLES_CONFIG[role.talentType].subKey;
          let subTypeObj = (exSubKey && role.profileItems.find(r => r.criteria === exSubKey)) || {};

          extraRoles.push({
            type: role.talentType,
            subKey: exSubKey,
            subType: exSubKey === 'speciality' ? subTypeObj.str2Value : subTypeObj.strValue,
          });
        });
      }
    }

    this.state = {
      talentType,
      extraRoles,
      roles: [{
        type: talentType,
        subKey: talentTypeSubKey,
        subType: talentSubType,
      }, ...extraRoles],
    };
  }

  deleteClicked = () => {
    const {
      user, savedSearch, actions, handleClose, apiToken,
    } = this.props;
    this.setState({ isDeleting: true });
    actions.deleteResource(apiToken, 'savedsearchs', savedSearch.id).then(() => {
      this.setState({ isDeleting: false });
      actions.getAllResources(apiToken, `savedsearchs/user/${user.id}/savedsearchs`);
      handleClose && handleClose(true);
    });
  };

  saveClicked = () => {
    const {
      user, savedSearch, history, actions, handleClose, updateColor, openPhoneVerification, resources, apiToken, talent,talentpiis,
    } = this.props;
    const {
      color, title, employmentType, range, hasSms, hasEmail, selectedRole, isDefaultRelief,
      address, city, state, postalCode,
      lat, lng, mobile, notificationFrequency, selectPracticeEnabled, staffLocationType, selectedPractices,
    } = this.state;
    const talentpiiId = talentpiis && talentpiis.length && talentpiis[0] && talentpiis[0].id;
    const updatedPII = {
      mobile: this.state.mobile,
    };

    this.setState({ isSaving: true });

    const data = {
      color,
      title,
      positionType: employmentType,
      city: city || null,
      state: state || null,
      postalCode: postalCode || null,
      lat: lat || null,
      lng: lng || null,
      address: address || null,
      range,
      hasSms: hasSms,
      hasEmail: hasEmail,
      talent_id: talent.id,
      admin_mode: true,
      notificationFrequency: 'REAL_TIME', // remove the Frequency section on Whisker Bot search page, when saving the only value 'REAL_TIME' is stored
      practices: !isDefaultRelief && selectPracticeEnabled && staffLocationType === 'SELECT_PRACTICES' ? selectedPractices : [],
      talentRole: selectedRole && selectedRole.type !== 'all' ? selectedRole : null,
    };
    // update user SMS notification information
    if (mobile) {
      actions.updateResource(apiToken, updatedPII, 'talentpiis', talentpiiId)
        .then(openPhoneVerification());
    } else if (hasSms && user.needMobileVerificationCode) {
      openPhoneVerification();
    }
    // update the search
    if (savedSearch) {
      actions.updateResource(apiToken, data, 'savedsearchs', savedSearch.id)
        .then((e) => {
          actions.addResource(apiToken, { action: 'talent_search' }, 'users', 'log-event');
          this.setState({ isSaving: false });
          actions.getAllResources(apiToken, `savedsearchs/user/${user.id}/savedsearchs`);
          handleClose && handleClose(true);
          
        });
    } else {
      actions.addResource(apiToken, data, 'savedsearchs')
        .then((e) => {
          actions.addResource(apiToken, { action: 'talent_search' }, 'users', 'log-event');
          this.setState({ isSaving: false });
          actions.getAllResources(apiToken, `savedsearchs/user/${user.id}/savedsearchs`);
          handleClose && handleClose(true);
        });
    }
    updateColor && updateColor(color);
  };

  renderStaffJobLocation = () => {
    const { classes, practices } = this.props;

    const {
      address,
      state,
      staffLocationType,
      selectedPractices = [],
      isDefaultReliefHome,
      isDefaultBulletin,
    } = this.state;

    if (isDefaultReliefHome || isDefaultBulletin) return null;
    return (
      (
        <Fragment>
          <div>
            <LocationSearchInput
              variant="outlined"
              address={address}
              onChange={(addressObj) => {
                this.setState({
                  ...addressObj,
                  updated: true,
                  practicesInState: practices?.filter(p => p.state === addressObj.state && (p.active === true && !p.deletedAt)) || [],
                  selectedPractices: selectedPractices.filter(p => p.state === addressObj.state),
                  staffLocationType: 'WHOLE_STATE',
                });
              }}
              fullWidth
              placeholder="State"
              errorBorder={Boolean(!address)}
              stateSearch
            />
          </div>
          {state &&
            (
              <RadioGroup
                aria-label='location options'
                name='locationOptions'
                row
                value={staffLocationType}
                onChange={e => this.setState({ staffLocationType: e.target.value })}
                justifyContent='center'
              >
                <Grid container>
                  {STAFF_WB_LOCATION_OPTIONS.map(option => (
                    <Grid
                      item
                      xs={6}
                      content='center'
                      key={option.value}
                    >
                      <FormControlLabel
                        value={option.value}
                        label={(
                          <Typography
                            align='center'
                          >{option.label}</Typography>
                        )}
                        control={<Radio />}
                        labelPlacement='right'
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            )}
        </Fragment>
      )
    );
  }

  renderPracticeAutocomplete = () => {
    const { classes, isMobile } = this.props;
    const {
      isDefaultBulletin,
      isDefaultRelief,
      isDefaultReliefHome,
      staffLocationType,
      practicesInState,
      state,
      selectedPractices,
      inactiveSelectedPractices,
    } = this.state;

    const disabledEdit = isDefaultBulletin || isDefaultRelief || isDefaultReliefHome;
    if (!(isDefaultBulletin || isDefaultReliefHome) && (staffLocationType === 'WHOLE_STATE' || !state)) return null;
    return (
      <div>
        {isMobile && !disabledEdit && (
          <div style={{ display: "flex" }}>
            <Typography className={classes.searchPracticeLink} onClick={() => this.setState({ isOpenSearchPractice: true })}>
              <PlaceIcon /> Search practices by map
            </Typography>
          </div>
        )}
        <div className={classes.label} style={{ marginTop: '8px', height: 'auto' }}>
          <Typography className={classes.searchText}>
            Practices
          </Typography>
        </div>
        <PracticeAutocomplete
          disabled={disabledEdit}
          handleStateChange={(val) => {
            if (val) {
              return this.setState({ selectedPractices: val, updated: true });
            }
          }}
          title={!disabledEdit ? 'Enter Practice Name' : ''}
          items={practicesInState}
          form
          value={selectedPractices}
          labelCount={3}
          lettersTypedToShowOptions={0}
        />
        {!isEmpty(inactiveSelectedPractices)
          && (
            <div className={classes.label}>
              <Typography className={classes.searchText}>
                {inactiveSelectedPractices.length > 1
                  ? `Practices ${inactiveSelectedPractices.map(p => p.name).join(', ')} are inactive.`
                  : `Practice ${inactiveSelectedPractices.map(p => p.name)[0]} is inactive.`}
              </Typography>
            </div>
          )
        }
      </div>
    );
  }

  handleOnMobileChange = (event) => {
    const input = event.target.value;
    this.setState({
      mobile: pruneMobileInput(input),
      updated: true,
    });
  }

  componentDidMount() {
    this.setData()
  }

  componentDidUpdate(prevProps) {
    const { savedSearch, resources } = this.props;
    if (!isEqual(prevProps?.savedSearch, savedSearch)) {
      this.setState({
        hasSms: savedSearch.hasSms,
        hasEmail: savedSearch.hasEmail,
      });
    }
  }

  onConfirmAddPractice = (practice) => {
    let selectedPractices = [...this.state.selectedPractices];
    selectedPractices.push(practice);
    selectedPractices = uniqBy(selectedPractices, 'id');
    this.setState({ selectedPractices, updated: true });
    if (this.state.isOpenSearchPractice) {
      this.setState({ isOpenSearchPractice: false });
    }
  }

  render() {
    const {
      classes, saveButtonText, enableDelete, user, savedSearch, resources, isMobile, isExternshipUser, talent,
    } = this.props;
    const { isOpenSearchPractice } = this.state;
    const hasMobileVerification = user.needMobileVerificationCode;
    const mobileVerified = user.isMobileVerified;
    const saveText = saveButtonText || 'DONE!';
    const {
      color, title, employmentType, range, hasSms, hasEmail, mobile,
      address, city, state, country, updated, selectPracticeEnabled, selectedPractices,
      help, helpMsg, isDefaultBulletin, isDefaultRelief, isDefaultReliefHome, selectedRole, extraRoles, roles,
    } = this.state;
    const disabledEdit = isDefaultBulletin || isDefaultRelief || isDefaultReliefHome;
    const showTalentRoles = !isEmpty(extraRoles) && !disabledEdit;

    let showRedundantTalentRoles = false;
    let redundantTalentSubType;
    const savedSearchObj = savedSearch || { hasEmail: true };

    if (isEmpty(extraRoles) && (savedSearchObj && savedSearchObj.talentRole) && !disabledEdit) {
      const roleObj = TALENT_ROLES_CONFIG[savedSearchObj.talentRole.type] || {};
      const subObj = (roleObj.subTypeList && roleObj.subTypeList.find(s => s.key === savedSearchObj.talentRole.subType)) || {};
      redundantTalentSubType = subObj.title || subObj.category;
      showRedundantTalentRoles = true;
    }
    const selectPracticeValid = this.state.staffLocationType === 'SELECT_PRACTICES' && this.state.selectedPractices.length === 0;
        
    const isValid = title && employmentType && (country || state || city || selectedPractices.length)
      && (hasSms && !mobileVerified && !hasMobileVerification ? Boolean(mobile) : true) && !selectPracticeValid;

    return (
      <>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ padding: 20, paddingBottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: (isMobile || isExternshipUser) ? '100%' : '50%', minWidth: 300 }}>
              <div className={classes.closeButtonContainer}>
                <div style={{ width: '100%' }}>
                  <Typography className={classes.searchText} style={{ height: '24px ' }}>Color Tag</Typography>
                  <FormControl variant="outlined">
                    <Select
                      input={<OutlinedInput labelWidth={0} style={{ height: '35px' }} />}
                      value={color}
                      className={classes.colorChooser}
                      onChange={e => this.setState({ color: e.target.value, updated: true })}
                    >
                      {
                        COLORS.map(color => (
                          <MenuItem
                            key={color}
                            value={color}
                          >
                            <FiberManualRecordRoundedIcon
                              style={{ color: `#${color}`, fontSize: '19px', marginLeft: '8px' }}
                            />
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div
                className={classes.inputContainer}
              >
                <Fragment>
                  <div className={classes.label}>
                    <Typography className={classes.searchText}>
                      Search Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </div>
                  <TextField
                    disabled={disabledEdit}
                    className={classes.formControlWithLabel}
                    variant="outlined"
                    inputProps={{ autoComplete: 'new-password' }}
                    value={title}
                    onChange={e => this.setState({ title: e.target.value, updated: true })}
                    fullWidth
                    placeholder="Name your search"
                    InputProps={{
                      style: textfieldInput,
                    }}
                  />
                </Fragment>

                <div className={classes.label}>
                  <Typography className={classes.searchText}>
                    Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </div>
                <FormControl fullWidth variant="outlined" className={classes.select}>
                  <Select
                    disabled={disabledEdit}
                    input={<OutlinedInput style={{ height: '35px' }} />}
                    onChange={e => this.setState({ employmentType: e.target.value, updated: true })}
                    value={employmentType}
                    displayEmpty={true}
                    renderValue={(_selected) => {
                      if (_selected.length === 0) {
                        return <span style={{ fontSize: '16px', color: 'rgba(0,0,0,.4)' }}>{employmentType || 'Select search type'}</span>;
                      }
                      return _selected;
                    }}
                  >
                    {
                      getEmploymentTypes(talent).map(type => (
                        <MenuItem
                          key={type}
                          value={type}
                          className={classes.employmentTypeItem}
                        >
                          {EMPLOYMENT_TYPE_TITLES[type] || type}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {showTalentRoles && (
                  <Fragment>
                    <div className={classes.label}>
                      <Typography className={classes.searchText}>
                        Role
                      </Typography>
                    </div>
                    <FormControl fullWidth variant="outlined">
                      <Select
                        disabled={disabledEdit}
                        input={<OutlinedInput />}
                        onChange={(e) => {
                          const subTypeVal = e.target.value;
                          const selectedRoleObj = subTypeVal === 'all'
                            ? allTypesObj
                            : {
                              type: roles.find(r => r.subType === subTypeVal).type,
                              subType: subTypeVal,
                              subKey: roles.find(r => r.subType === subTypeVal).subKey,
                            };
                          this.setState({
                            selectedRole: selectedRoleObj,
                            updated: true,
                          });
                        }}
                        value={selectedRole.subType}
                        sx={{
                          '& .MuiSelect-select': {
                            padding: '9px 24px 9px 9px',
                          }
                        }}
                      >
                        {
                          [allTypesObj, ...roles].map((role) => {
                            const roleObj = TALENT_ROLES_CONFIG[role.type] || {};
                            if (roleObj) {
                              const subObj = (roleObj.subTypeList && roleObj.subTypeList.find(s => s.key === role.subType)) || {};
                              return (
                                <MenuItem
                                  key={subObj.key}
                                  value={subObj.key}
                                  className={classes.employmentTypeItem}
                                >
                                  {subObj.title || subObj.category}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                        }
                      </Select>
                    </FormControl>
                  </Fragment>
                )}
                {showRedundantTalentRoles && (
                  <Fragment>
                    <div className={classes.label}>
                      <Typography className={classes.searchText}>
                        Role
                      </Typography>
                    </div>
                    <TextField
                      disabled
                      className={classes.formControlWithLabel}
                      variant="outlined"
                      value={redundantTalentSubType}
                      fullWidth
                      placeholder=""
                      InputProps={{
                        style: textfieldInput,
                      }}
                    />
                  </Fragment>
                )}
                <div className={classes.label}>
                  <Typography className={classes.searchText}>
                    {selectPracticeEnabled && !disabledEdit ? 'Job Location (State + Practices)' : 'Job Location'}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </div>
                {selectPracticeEnabled && !isDefaultRelief ?
                  this.renderStaffJobLocation()
                  : isDefaultRelief
                    ? (
                      <TextField
                        disabled={disabledEdit}
                        className={classes.formControlWithLabel}
                        variant="outlined"
                        value={address}
                        fullWidth
                        InputProps={{
                          style: textfieldInput,
                        }}
                      />
                    )
                    : (
                      <div>
                        <LocationSearchInput
                          variant="outlined"
                          address={address}
                          onChange={e => this.setState({ ...e, updated: true })}
                          fullWidth
                          placeholder="State, City or Zip Code"
                          errorBorder={Boolean(!address)}
                        />
                      </div>
                    )
                }
                {
                  selectPracticeEnabled && !isDefaultRelief &&
                  this.renderPracticeAutocomplete()
                }
                {city &&
                  <div className={classes.label}>
                    <Typography className={classes.searchText}>Search Within Range (miles)</Typography>
                  </div>
                }
              </div>
              {city && (
                <PrettoSlider
                  valueLabelDisplay="on"
                  aria-label="pretto slider"
                  defaultValue={20}
                  max={300}
                  value={range}
                  marks={[{
                    value: 0,
                    label: '0',
                  }, {
                    value: 301,
                    label: '300mi',
                  }]}
                  onChange={(e, value) => this.setState({ range: value, updated: true })}
                />
              )
              }

              <div className={classes.notificationsContainer}>
                <div className={classes.notifications}>
                  <div className={classes.searchText}>Notify me by:</div>
                  {(!hasEmail && !hasSms)
                    ? <NotificationsOffIcon style={{ color: iconGray }} />
                    : <NotificationsIcon color='secondary' />
                  }
                </div>
                {(!hasEmail && !hasSms) && (
                  <Typography className={classes.warning}>
                    You won't be notified for matches by this Whisker Search!
                  </Typography>
                )}
                <Grid containner style={{ display: 'flex', width: '100%' }}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={hasEmail}
                          color="primary"
                          className={classes.checkbox}
                          onChange={e => this.setState({ hasEmail: !hasEmail, updated: true })}
                          value="hasEmail"
                        />
                      )}
                      label="Email"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={hasSms}
                          color="primary"
                          className={classes.checkbox}
                          onChange={() => this.setState({ hasSms: !hasSms, updated: true, mobile: hasSms && '' })}
                          value="hasSms"
                        />
                      )}
                      label="SMS"
                    />
                  </Grid>
                </Grid>
                <Grid containner style={{ display: 'flex', width: '100%' }}>
                  {hasEmail && !user.isVerified
                    && (
                      <div className={classes.warningIconAndText}>
                        <img src={`${IMG_STORAGE_LINK}icons/warning.svg`} className={classes.verifyWarningIcon} />
                        <Typography className={classes.noEmailWarningText}>
                          Unverified email, click save and go to profile page
                        </Typography>
                      </div>
                    )
                  }
                </Grid>
                <Grid containner style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                  {hasSms && !mobileVerified && hasMobileVerification
                    && (
                      <Grid item xs={12}>
                        <div className={classes.warningIconAndText}>
                          <img src={`${IMG_STORAGE_LINK}icons/warning.svg`} className={classes.verifyWarningIcon} />
                          <Typography className={classes.noEmailWarningText}>
                            Unverified number, click save and go to profile page
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    {(hasSms && !mobileVerified && !hasMobileVerification)
                      && (
                        <div>
                          <Typography className={classes.searchText}>
                            Phone number
                            <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={mobile || ''}
                            placeholder='000-000-0000'
                            className={classes.smsVerify}
                            InputProps={{ className: classes.smsInput, style: textfieldInput, }}
                            onChange={e => this.handleOnMobileChange(e)}
                            fullWidth
                          />
                        </div>
                      )
                    }
                  </Grid>
                </Grid>
              </div>
              <Popover
                open={!!help}
                anchorEl={help}
                onClose={e => this.setState({ help: null })}
              >
                <Paper className={classes.helpPopover}>
                  <Typography className={classes.helpPopoverText}>{helpMsg}</Typography>
                </Paper>
              </Popover>
            </div>
            {(!isMobile && !isExternshipUser && user.userRole === 'PRIVATE_TALENT') && <div className={classes.mapSearchContainer} style={{ width: "360px" }}>
              <SearchPracticeByMap
                practicesInState={this.state.practicesInState}
                onConfirm={this.onConfirmAddPractice}
                disabled={disabledEdit || (!(isDefaultBulletin || isDefaultReliefHome) && (this.state.staffLocationType === 'WHOLE_STATE' || !state))}
                address={this.state.address}
                selectedPractices={selectedPractices}
                isShowPractices={this.state.staffLocationType === 'SELECT_PRACTICES'}
              />
            </div>}
          </div>
          <div style={{ padding: '15px 20px', background: '#FBFBFE', position: isMobile ? 'fixed' : 'unset', bottom: isMobile ? 0 : 'auto', left: isMobile ? 0 : 'auto', right: isMobile ? 0 : 'auto' }}>
            <Grid container className={classes.actions} spacing={1} justifyContent='flex-end'>
              {
                enableDelete
                  ? (
                    <React.Fragment>
                      <Grid item >
                        <Button
                          variant='contained'
                          color='secondary'
                          fullWidth
                          onClick={() => !this.state.isDeleting && this.deleteClicked()}
                          endIcon={this.state.isDeleting ? <CircularProgress size={20} /> : null}
                        >
                          DELETE
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classes.saveButton}
                          variant="contained"
                          disabled={savedSearch ? (!isValid || !updated) : !isValid}
                          fullWidth
                          onClick={e => !this.state.isSaving && this.saveClicked()}
                          endIcon={this.state.isSaving ? <CircularProgress size={20} /> : null}
                        >
                          {saveText}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )
                  : (
                    <Grid item>
                      <Button
                        variant="contained"
                        disabled={savedSearch ? (!isValid || !updated) : !isValid}
                        fullWidth
                        className={classes.saveButton}
                        onClick={e => !this.state.isSaving && this.saveClicked()}
                        endIcon={this.state.isSaving ? <CircularProgress size={20} /> : null}
                      >
                        {saveText}
                      </Button>
                    </Grid>
                  )
              }
            </Grid>
          </div>
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isOpenSearchPractice}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className={classes.headerContainer} style={{ justifyContent: 'flex-start' }}>
              <IconButton className={classes.backBtn} onClick={() => this.setState({ isOpenSearchPractice: false })}>
                <ChevronLeftIcon />
              </IconButton>
              <Typography className={classes.title}>{savedSearch ? 'Edit Whisker Search' : 'Whisker Search'}</Typography>
              <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
            </div>
          </div>
          <div className={classes.drawerBody}>
            <SearchPracticeByMap
              practicesInState={this.state.practicesInState}
              onConfirm={this.onConfirmAddPractice}
              disabled={(!(isDefaultBulletin || isDefaultReliefHome) && (this.state.staffLocationType === 'WHOLE_STATE' || !state))}
              isMobile={isMobile}
              address={address}
              isShowPractices={this.state.staffLocationType === 'SELECT_PRACTICES'}
            />
          </div>
        </Drawer>
      </>
    );
  }
}

export default withStyles(SavedSearchFormStyle)(withRouter(SavedSearchForm));
