import Immutable from 'seamless-immutable';
import DisplayImages from '../components/subcomponents/DisplayImages';
import Hours from '../components/subcomponents/Hours';
import Dropdown from '../components/subcomponents/Dropdown';
import CustomRadio from '../components/subcomponents/CustomRadio';
import { TextField } from '@mui/material';
import { isEqual, isEmpty } from 'lodash';
import { PATHWAY_AGGREGATOR_ID } from '../data/Constants';
import { EMPLOYMENT_TYPES, TALENT_ROLES_CONFIG, COLORS } from '../config/enums';
import {yellowCoronado} from "../css/style";
/**
 *
 * @param items an array of strings
 */
export const listArrayToString = (items) => {
  if (!items) {
    return '';
  }
  const mutableItems = Immutable.asMutable(items, { deep: true });

  if (items.length > 1) {
    const lastIndex = items.length - 1;
    const lastItem = items[lastIndex];
    mutableItems[lastIndex] = `and ${lastItem}`;
  }

  if (mutableItems.length === 2) {
    return mutableItems.join(' ');
  }

  return mutableItems.join(', ');
};

const monthDict = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const getMonthName = month => ((month > 0 && month < 13) ? monthDict[month] : null);


/**
 *  If fieldData is null, undefined, NaN, empty string (""), 0, or false => show as N/A
 fieldData for Birth Day and Birth Month have default vals of 0 => show as N/A
 * */
export const formatFieldData = (field, fieldData) => {
  if (!fieldData) return 'N/A';
  if (['category', 'productTax', 'categoryTax', 'tier'].includes(field.key)) return fieldData.name;
  if (field.key == 'birthMonth') return getMonthName(fieldData);
  return fieldData;
};

export const showAvailableOptions = (customizations) => {
  const availOptions = [];
  if (customizations) {
    customizations.map((customization) => {
      let placeHolder = {
        id: customization.id,
        name: customization.name,
        isDisabled: true,
      };
      availOptions.push(placeHolder);
      availOptions.push.apply(availOptions, customization.options);
    });
  }
  return availOptions;
};

export const getFieldTypeTag = (fieldType) => {
  switch (fieldType) {
    case 'image':
      return DisplayImages;
    case 'hours':
      return Hours;
    case 'dropdown':
      return Dropdown;
    case 'boolean':
      return CustomRadio;
    default:
      return TextField;
  }
};

/**
 * the usage is similar to mapStateToProps
 * returns null if no resourceToMatch is 0, null, undefined or an empty object
 * @param {*} resources
 * @param {*} match
 * @param {*} newResources
 */
export const findItemByID = (resources, match, newResources) => {
  const resourceToMatch = newResources || resources;
  if (!resourceToMatch || Object.keys(resourceToMatch).length == 0) return null;
  if (Object.keys(resourceToMatch).length != 0) {
    const { resource, resourceId } = match.params;
    const resourceItems = resourceToMatch[resource];
    const data = resourceItems && resourceItems.filter(item => item.id.toString() === resourceId.toString());
    return data && data[0];
  }
};

/**
 * the usage is similar to mapStateToProps
 * returns null if findItemByID returns null
 * @param {*} resources
 * @param {*} match
 * @param {*} newResources
 */
export const findSubItemByID = (resources, match, newResources) => {
  const { subResource, subResourceId } = match.params;
  const resourceItem = findItemByID(resources, match, newResources);
  if (resourceItem) {
    const subResourceItems = resourceItem[subResource];
    if (subResourceItems) {
      const data = subResourceItems.filter(item => item.id.toString() === subResourceId.toString());
      return data[0];
    }
  } else {
    return null;
  }
};

export const depluralizeResourceName = (resourceName) => {
  switch (resourceName.toLowerCase()) {
    case 'privateusers':
      return 'STAFF';
    default:
      return resourceName.substring(0, resourceName.length - 1);
  }
};
const chartColors = ['#FFD9E1', '#FFECD9', '#DBF2F2', '#D7ECFB', '#EBE0FF', '#F4F5F5', '#FFF5DD'];
const chartBorderColors = ['#FF88A1', '#FFB770', '#4BC0C0', '#68B9F0', '#B189FF', '#C9CBCF', '#FFE3A3'];
export const getChartColor = (index) => {
  const i = parseInt(index) || 0;
  return {
    bgColor: chartColors[i % colors.length],
    borderColor: chartBorderColors[i % colors.length],
  };
};

const colors = ['#4CAF50', '#FF8A65', '#42A5F5', '#8BC34A', '#006064', '#311B92', '#006064', '#3949AB', '#FFA726', '#F44336', '#880E4F', '#29B6F6', '#81C784', '#1E88E5', '#0D47A1', '#FF6F00', '#00796B', '#F9A825', '#0097A7', '#26A69A', '#FFC107', '#FB8C00', '#AED581', '#B71C1C', '#388E3C', '#00BCD4', '#0277BD', '#E91E63', '#AB47BC', '#D4E157', '#7B1FA2', '#F57F17', '#558B2F', '#7E57C2', '#1A237E', '#FF8F00', '#FBC02D'];
export const badgeColor = (str) => {
  if (!str) {
    str = '';
  }
  if (str.toString().toLocaleLowerCase() === 'public') {
    return '#4bbdad';
  }
  if (str.toString().toLocaleLowerCase() === 'superhero') {
    return '#243060';
  }
  if (['private-staff', 'private', 'thrive', 'thrive partner'].indexOf(str.toString().toLocaleLowerCase()) !== -1) {
    return '#E0592A';
  }

  if (str.toString().toLocaleLowerCase() === 'coronado er') {
    return yellowCoronado;
  }

  const st = str || '0';
  var i = parseInt(st);
  return colors[i % colors.length];
};

/**
 * @param milliseconds a number
 * @returns date string with form yyyymmdd
 */
export const formMillisecondToDateString = (milliseconds) => {
  const timePoint = Date.now() - milliseconds * 1000;
  let time = new Date(timePoint);
  const year = time.getFullYear();
  const month = (time.getMonth() + 1).toLocaleString('en-us', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = time.getDate().toLocaleString('en-us', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const dateString = `${year}${month}${day}`;
  return dateString;
};

export const validateMobile = (mobile) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(String(mobile).toLowerCase());
};

export const validateEmail = (email) => {
  var re = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  return re.test(String(email).toLowerCase());
};


export const utils = {
  queryParamsToObject: (str) => {
    if (!str) return {};
    const search = str.substring(1);
    return JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  },

  setPrevFilter: (filterStr, list, key, callback) => {
    const splitted = filterStr.split(',');
    const selectedItems = [];
    for (let item of splitted) {
      const selectedItem = list.find(x => String(x[key]) === item);
      if (selectedItem) {
        selectedItems.push(selectedItem);
      }
    }
    if (callback) {
      callback(selectedItems);
    }
  },

  toTitleCase: (str) => {
    if (str.toLowerCase() === 'vet-tech-or-assistant') {
      return 'Vet Tech / Vet Assistant';
    } else if (str === 'dvm-doctor-of-veterinary-medicine') {
      return 'DVM';
    } else {
      str.replace(new RegExp('-', 'g'), ' ');
    }
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  },

};

export const isDesktop = () => {};

export const mapConnections = (connections, getTalentTypeIcon) => connections.filter(connection => connection.id !== 'viewMore').map(connection => ({
  key: connection.id,
  headerImage: getTalentTypeIcon(connection, true),
  header: `${connection.talentPII.firstName} ${connection.talentPII.lastName}`,
  title1: 'POSITION POST',
  value1: formatPostTitle(connection.jobPosting.title),
  title2: 'EMPLOYEE TYPE',
  value2: utils.toTitleCase(connection.jobPosting.talentType),
  unreadMessagesCount: connection.unreadMessagesCount,
}));

export const getBackgroundColor = i => (i % 2 === 0 ? '#ffffff' : '#f1fffd');

export const prunePhoneNumber = (input) => {
  const numbersOnly = input.match(/[0-9]/g);
  return numbersOnly ? numbersOnly.join('') : '';
};

export const scrollToItem = (prevProps, currentProps, item) => {
  if (!isEqual(prevProps, currentProps)) {
    if (!isDesktop(currentProps.width)) {
      try {
        const verticalScroll = sessionStorage.getItem(item);
        window.scrollTo(0, verticalScroll || 0);
      } catch (e) {
        console.log(e);
      }
    }
  }
};

export const handleTrailingSpaces = str => str.trimEnd();

export const checkQueryParam = (field) => {
  let url = window.location.href;
  if (url.indexOf(`?${ field }=`) != -1) return true;
  else if (url.indexOf(`&${ field }=`) != -1) return true;
  return false;
};

export const updateAdminAccessList = (admin, actions, aggregator) => {
  admin.accessList = admin.accessList.map((access) => {
    if (access.type === 'aggregator' && access.id === aggregator.id) {
      access.stripeCustomerId = aggregator.stripeCustomerId;
      access.stripeSubscriptionId = aggregator.stripeSubscriptionId;
      access.subscriptionStatus = aggregator.subscriptionStatus;
    }
    return access;
  });
  localStorage.setItem('admin', JSON.stringify(admin));
  actions.setAdmin(admin);
};

/**
 * Determine if user (aggregator) has completed Stripe checkout process.
 * UserAccess with only a stripeCustomerId means the checkout process is incomplete.
 * Return value is used in Stripe checkout process for redirection.
 *
 * @param userAccess aggregator
 * @returns {*|boolean}
 */
export const isStripeEnabled = userAccess => (userAccess.stripeCustomerId && userAccess.subscriptionStatus)
  || (!userAccess.stripeCustomerId && !userAccess.subscriptionStatus) || isEnterpriseAggregator(userAccess);

export const isEnterpriseAggregator = aggregator => aggregator.subscriptionStatus === 'active' && aggregator.stripeSubscriptionId === null;

export const getUserAccess = (admin, type) => {
  const access = admin && admin.accessList ? admin.accessList.filter(item => item.type === type) : [];
  return access.length ? access[0] : {};
};

export const isEnterpriseUser = (admin, actions) => {
  const aggregator = getUserAccess(admin, 'aggregator');
  if (!isEmpty(aggregator)) {
    return isEnterpriseAggregator(aggregator);
  }

  const practice = getUserAccess(admin, 'practice');
  if (!isEmpty(practice) && practice.aggregator_id) {
    return actions.getAllResources(admin.token, 'aggregators', practice.aggregator_id)
      .then((res) => {
        if (!res.error) {
          const practiceAggregator = res.response;
          return isEnterpriseAggregator(practiceAggregator);
        }
      });
  }

  return false;
};

export const filterBadges = (admin, badges = []) => badges
  .filter(badge => !badge.isLocked && admin.accessList.some(access => (access.type === 'aggregator'
    ? badge.aggregator_id === access.id
    : badge.aggregator_id === access.aggregator_id)));

/**
 * Get aggregator IDs related to a user
 *
 * @param user object
 * @returns array
 */
export const getAggregatorIds = (user) => {
  if (!user) return [];
  let aggregator = getUserAccess(user, 'aggregator');
  if (!isEmpty(aggregator)) return [aggregator.id];
  const practices = user && user.accessList ? user.accessList.filter(item => item.type === 'practice') : [];
  if (!isEmpty(practices)) return practices.map(practice => practice.aggregator_id);
  return [];
};

/**
 * Determine if a user is a Thrive Pet Health Care user
 *
 * @param user an object
 * @returns {*|boolean}
 */
export const isTPHCUser = (user) => {
  const aggregatorIds = getAggregatorIds(user) || [];
  return !isEmpty(aggregatorIds.filter(id => Number(id) === PATHWAY_AGGREGATOR_ID));
};

export const isReliefOrExternshipType = typeOfEmployment => typeOfEmployment === 'Relief' || typeOfEmployment === 'Externship';

export const formatPostTitle = title => (title ? title.replace(/\^|\$T/g, '') : '');

export const updateLocalAdmin = (admin) => {
  localStorage.setItem('admin', JSON.stringify(admin));
};

export const getTimeCategory = (time) => {
  const hour = time.hours();
  if (hour >= 6 && hour < 12) {
    return 'MORNING';
  } else if (hour >= 12 && hour < 18) {
    return 'AFTERNOON'; //SWING
  } else {
    return 'NIGHT';
  }
};

export const getPriorityPointsInfo = (time, isPriorityHospital, status) => {
  // if (status !== 'available') return null;
  let points = 0;
  let redAlert = false;
  const cat = getTimeCategory(time);
  if (isPriorityHospital) {
    points += 2;
  }
  if (cat === 'AFTERNOON') {
    points += 1;
  } else if (cat === 'MORNING') {
    if (time.day() === 0 || time.day() === 6) { // weekend
      points += 3;
    } else {
      points += 2;
    }
  } else if (cat === 'NIGHT') {
    if (time.day() === 0 || time.day() === 6 || time.day() === 5) { // weekend + friday night
      points += 4;
    } else {
      points += 3;
    }
  }
  // within 7 days
  // if (time >= Date.now() && time - Date.now() < 7 * 24 * 60 * 60 * 1000) {
  //   points += 5;
  //   redAlert = true;
  // }
  return { points, redAlert };
};

export const geodistance = (lat1, lon1, lat2, lon2) => {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
};

export const getEmploymentTypes = (talent) => {
  const employmentTypes = [...EMPLOYMENT_TYPES];
  if (talent && talent.type === 'dvm-doctor-of-veterinary-medicine') {
    if (talent.isStudent) employmentTypes.push('VetAssistant-Relief');
    else employmentTypes.splice(employmentTypes.indexOf('Externship'), 1);
  }
  return employmentTypes;
};

export const pruneMobileInput = (input) => {
  const numbersOnly = input.match(/[0-9]/g);
  return numbersOnly ? numbersOnly.join('') : '';
};

export const updateColorsUsed = (color, colorsUsed) => {
  const index = COLORS.indexOf(color);
  let colorsUsedArray = colorsUsed;
  colorsUsedArray[index] = true;
  if (colorsUsedArray.indexOf(false) === -1) {
    colorsUsedArray = new Array(COLORS.length).fill(false);
  }
  return {
    colorsUsed: colorsUsedArray,
    nextColor: findFirstNewColor(colorsUsedArray),
  };
};

export const findFirstNewColor = (colorsUsed) => {
  const index = colorsUsed.indexOf(false);
  if (index !== -1) {
    return index;
  } else {
    return 0;
  }
};

export const MANAGE_SAVED_SEARCH_PERMISSION = 'talent.saved_search.manage';
export const hasPermission = (admin, permission) => {
  if (!admin?.permissions) return false;
  return admin.permissions.includes(permission);
};
