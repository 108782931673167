import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Grid, Typography, Icon, Button, Badge,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { DataGrid } from '@mui/x-data-grid';
import { withRouter } from 'react-router';
import { isEmpty, sortBy } from 'lodash';

import { commonResourceStyle, green } from '../../css/style';
import { UserViewStyles } from './styles/UserViewStyles';
import LoadingBar from '../subcomponents/LoadingBar';
import {
  EXPERIENCE_LEVELS_CONFIG,
  EXPERIENCE_TYPE_CONFIG,
  VET_TYPES_TITLES,
  BASE_URLS,
  JOB_TYPE_SUB_CATEGORY_OBJ,
  IMG_STORAGE_LINK,
  TALENT_ROLES_CONFIG,
} from '../../data/Constants';

import {badgeColor, hasPermission, MANAGE_SAVED_SEARCH_PERMISSION} from '../../utils/Functions';
import PrivateUsersForm from './PrivateUsersForm';
import AddFastpassPracticeDialog from '../subcomponents/AddFastpassPracticeDialog';
import SavedSearchDialog from '../subcomponents/SavedSearchDialog';

const styles = commonResourceStyle();

export class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePractices: [],
      fastpassData: [],
      loaded: false,
      openAddPracticeDialog: false,
      openSearchDialog: false,
      deletedRowIds: [],
    };
  }

  componentDidMount = async () => {
    const {
      actions,
      match,
      apiToken,
      resources,
    } = this.props;
    const { adminPractices } = resources;
    const { userId } = match && match.params;
    actions.getOneResource(apiToken, `users/${userId}`);
    actions.getAllResources(apiToken, `savedsearchs/user/${userId}/savedsearchs`);
    actions.getAllResources(apiToken, `talentpiis/user/${userId}/talentpiis`);
    actions.getAllResources(apiToken, `talents/user/${userId}/talents`);
    actions.getAllResources(apiToken, `talentprofileitems/user/${userId}/talentprofileitems`);
    actions.getAllResources(apiToken, `useraccesses/user/${userId}/useraccesses`);
    actions.getAllResources(apiToken, `badges/user/${userId}/badges`);
    actions.getAllResources(apiToken, 'criterias');
    if (!adminPractices) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        this.setState({
          activePractices: (resp?.response || []).filter(p => !p.deletedAt && p.active),
        }, () => this.retrieveFastpassData());
      });
    } else {
      this.setState({
        activePractices: adminPractices.filter(p => !p.deletedAt && p.active),
      }, () => this.retrieveFastpassData());
    }
  };

  /**
   * This is used to traverse the resources object
   * A user's related assocations are stored retrieved differently
   */
  getResourceValue = (key, appendString) => {
    const {
      match,
      resources,
    } = this.props;
    const { userId } = match.params;
    if (appendString) {
      return resources && resources[`${key}/${appendString}/${userId}/${key}`];
    } else {
      return resources && resources[`${key}/${userId}`];
    }
  };

  // not being used
  getCriteriaName = (criteriaId) => {
    const { resources } = this.props;
    let criteriaList = resources && resources.criterias;
    let criteriaItem = criteriaList && criteriaList.find(criteria => criteria.id === criteriaId);
    return criteriaItem ? criteriaItem.name : '';
  };

  // not being used
  getCriteriaValue = (talentProfileItems, criteriaId) => {
    let talentProfileItem = talentProfileItems && talentProfileItems.find(item => item.criteria_id === criteriaId);
    // TODO: implement custom selectors, etc
    return talentProfileItem ? talentProfileItem.strValue || talentProfileItem.numValue : '';
  };

  getBadge(title, id) {
    return (
      <Button
        key={title}
        style={{
          backgroundColor: badgeColor(title || id),
          cursor: 'default',
          ...styles.badgeFilter,
        }}
      >
        {title}
      </Button>
    );
  }

  getExperienceDetails() {
    const { classes } = this.props;
    const talentProfileItems = this.getResourceValue('talentprofileitems', 'user');
    const talent = this.getResourceValue('talents', 'user');
    if (!talentProfileItems || !talent) {
      return '';
    }
    const items = talentProfileItems.reduce((g, i) => {
      const criteriaName = this.getCriteriaName(i.criteria_id);
      g[criteriaName] = g[criteriaName] || [];
      let val = i.strValue === 'CT / UT No Cert' ? 'Other / None' : i.strValue;
      if (i.str2Value && i.str2Value !== i.strValue) {
        val = `${i.str2Value} (${i.strValue})`;
      }
      if (i.numValue) {
        val = i.numValue;
      }
      if (criteriaName === 'extra-roles') {
        val = PrivateUsersForm.setExtraRoles(i);
      }
      g[criteriaName].push(val);
      return g;
    }, {});

    var vetTitle = VET_TYPES_TITLES[talent.type];
    if (talent.isStudent) {
      if (talent.type === 'dvm-doctor-of-veterinary-medicine') {
        vetTitle = 'Veterinary Student Extern';
      } else {
        vetTitle = `${vetTitle} Student Extern`;
      }
    }
    const itemKeys = talent.type && Object.keys(EXPERIENCE_TYPE_CONFIG)
      .filter(item => EXPERIENCE_LEVELS_CONFIG[talent.type][item] || talent.isStudent && EXPERIENCE_TYPE_CONFIG[item].isStudent) || [];

    let talentExperience = [{
      key: 'Employee type',
      value: vetTitle,
    }, ...itemKeys
      .filter(item => items[item]).map(k => ({
        key: EXPERIENCE_TYPE_CONFIG[k].title,
        value: items[k].map((v) => {
          if (k === 'job-type-sub-category') {
            return JOB_TYPE_SUB_CATEGORY_OBJ[v];
          }
          return v;
        }).join(', '),
      }))];

    if (!isEmpty(items['extra-roles'])) {
      const extraRoleItems = items['extra-roles'][0].map(item => ({
        key: `Additional role ${item.key}`,
        value: TALENT_ROLES_CONFIG[item.talentType].title,
        extraValue: item[TALENT_ROLES_CONFIG[item.talentType].subKey],
      }));
      talentExperience = talentExperience.concat(extraRoleItems);
    }

    return (
      <div className={classes.newExperienceDetails}>
        {talentExperience?.map(exp => (
          <Fragment key={`${exp.key}_frag`}>
            <div key={exp.key} style={styles.experienceItem}>
              <div key={`${exp.key}_label`} className={classes.newExperienceItemTitle}>
                {exp.key}
                :
              </div>
              <div key={`${exp.key}_value`} className={classes.newExperienceItemValue}>{exp.value}</div>
            </div>
            { exp.extraValue && (
              <div key={`${exp.key}_extra`} style={styles.experienceItem}>
                <div key={`${exp.key}_extra_value_1`} className={classes.newExperienceItemTitle} />
                <div key={`${exp.key}_extra_value_2`} className={classes.newExperienceItemValue}>{`${exp.extraValue}`}</div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  }

  // not being used
  getConnectionDetails() {
    const { classes, theme } = this.props;
    const talentpiis = this.getResourceValue('talentpiis', 'user');
    const userbadges = this.getResourceValue('badges', 'user');
    const talent = this.getResourceValue('talents', 'user');
    const talentPII = talentpiis && talentpiis.length ? talentpiis[0] : null;
    if (!talent || !talentPII || !userbadges) {
      return '';
    }
    const { badges } = userbadges;
    const userviewStyleWithTheme = UserViewStyles(theme);

    return (
      <div className={classes.newConnectionDetailsLeft}>
        {talent?.about && (
          <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>
            {`About ${talentPII?.firstName} ${talentPII?.lastName}`}
          </Typography>
        )}
        {talent?.about && (<Typography>{talent.about || '-'}</Typography>)}

        {talent?.brief && (
          <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>
            Work Experience
          </Typography>
        )}
        {talent?.brief && (<Typography>{talent.brief || '-'}</Typography>)}

        <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>
          Badges
        </Typography>
        {talent?.private_aggregator_id && talent?.private_aggregator_id !== 6 && this.getBadge(talent.private_aggregator_id ? 'private-staff' : 'public')}
        {badges?.map(badge => this.getBadge(badge.title, badge.id))}
      </div>
    );
  }

  getPhoneNumber = (talentPII) => {
    if (talentPII && talentPII.length && talentPII[0]) {
      let mobile = talentPII[0].mobile;
      if (mobile) {
        if (mobile.includes('.')) {
          let numbers = mobile.split('.');
          let areaCode = numbers[0];
          let body = numbers.slice(1);
          return `(${areaCode}) ${body.join('-')}`;
        } else {
          return `(${mobile.substring(0, 3)}) ${mobile.substring(3, 6)}-${mobile.substring(6)}`;
        }
      }
    }
    return null;
  };

  getTalentRole = () => {
    const talent = this.getResourceValue('talents', 'user');
    return talent ? VET_TYPES_TITLES[talent.type] : '';
  };

  getHomePractice = () => {
    const talent = this.getResourceValue('talents', 'user');
    const { resources } = this.props;
    const { adminPractices } = resources;
    const practice_id = talent && talent.practice_id || -1;
    const practices_filter = adminPractices && adminPractices.filter(x => x.id === practice_id && x.active && !x.deletedAt);
    const practice = practices_filter && practices_filter.length && practices_filter[0] || null;
    return practice && practice.name || '';
  };

  retrieveFastpassData = async () => {
    const {
      actions,
      apiToken,
      match,
      resources,
    } = this.props;
    const { userId } = match && match.params;
    const { activePractices, deletedRowIds } = this.state;

    const fastpassRes = await actions.getAllResources(apiToken, `fastpass/user/${userId}`);
    const fastpassData = fastpassRes?.response;
    if (fastpassData) {
      let resultList = fastpassData.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id))) || [];
      resultList = sortBy(resultList, 'createdAt').reverse();

      this.setState({
        fastpassData: resultList,
      });
      this.setState({ activePractices });
    }
    this.setState({ loaded: true });
  };

  renderUserBanner = () => {
    const { classes, theme } = this.props;
    const user = this.getResourceValue('users');
    const talentPII = this.getResourceValue('talentpiis', 'user');
    const talent = this.getResourceValue('talents', 'user');
    const talentIcon = talent && `${IMG_STORAGE_LINK}talent-types/${talent.type}.png`;

    const userviewStyleWithTheme = UserViewStyles(theme);

    return !user
      ? <div />
      : (
        <div className={classes.banner}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <div className={classes.userIconContainer}>
                {talentIcon && <img src={talentIcon} className={classes.talentIconStyle} />}
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography sx={userviewStyleWithTheme.userName}>
                {user?.name}
              </Typography>
              <Typography sx={userviewStyleWithTheme.subtitle}>
                {this.getTalentRole()}
              </Typography>

            </Grid>
            <Grid item xs={12} md={5}>
              <Typography sx={userviewStyleWithTheme.subtitle}>
                <Icon sx={userviewStyleWithTheme.icon}>phone</Icon>
                {this.getPhoneNumber(talentPII) || '(000) 000-0000'}
              </Typography>
              <Typography sx={userviewStyleWithTheme.subtitle}>
                <Icon sx={userviewStyleWithTheme.icon}>email</Icon>
                {user?.email || 'No Email Provided'}
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
  };

  renderLocation = (search) => {
    const {
      city,
      state,
      practices,
    } = search;

    if (city) {
      return city;
    }

    if (state) {
      return state;
    }

    if (practices && practices.length) {
      return practices[0].name;
    }

    return '';
  };

  renderSavedSearches = () => {
    const {
      classes,
      resources,
      match,
      theme,
      actions,
      apiToken,
      admin,
    } = this.props;
    const { activePractices } = this.state;
    const { userId } = match && match.params;
    let savedSearches = resources && this.getResourceValue('savedsearchs', 'user');
    const user = resources && this.getResourceValue('users');
    const talent = resources && this.getResourceValue('talents', 'user');
    const talentProfileItems = resources && this.getResourceValue('talentprofileitems', 'user');
    const talentpiis = resources && this.getResourceValue('talentpiis', 'user');
    const userviewStyleWithTheme = UserViewStyles(theme);

    return !savedSearches || savedSearches.length == 0
      ? <div />
      : (
        <div>
          <SavedSearchDialog
            open={Boolean(this.state.openSearchDialog)}
            user={user}
            talent={talent}
            talentpiis={talentpiis}
            practices={activePractices}
            talentProfileItems={talentProfileItems}
            actions={actions}
            resources={resources}
            apiToken={apiToken}
            savedSearch={this.state.savedSearchItem}
            openPhoneVerification={() => this.setState({ openPhoneVerification: true })}
            handleClose={(isSaved) => {
              // if (isSaved) {
              //   actions.getAllResources(user.token, 'savedsearchs').then((resp) => {
              //     const searchTitles = resp?.response?.map(s => s.title);
              //     const updatedSearchTitles = searchTitles ? filterSearchTitles.filter(t => searchTitles.includes(t)) : [];
              //     this.setState({ filterSearchTitles: updatedSearchTitles });
              //     this.refreshMatches();
              //     this.getCalendarMatches();
              //     this.refreshStats();
              //   });
              // }
              this.setState({ openSearchDialog: false });
            }}
            enableDelete={this.state.enableDelete}
          />
          <Grid container justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>
            <Grid item>
              <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>Whiskerbot Search</Typography>
            </Grid>
            <Grid item>
              {hasPermission(admin, MANAGE_SAVED_SEARCH_PERMISSION) && (
                <Button
                  variant="contained"
                  sx={userviewStyleWithTheme.formBtnSmall}
                  onClick={() => this.setState({ openSearchDialog: true, savedSearchItem: null, savedSearchId: null })}
                >
                  <span style={{ verticalAlign: 'super' }}>ADD</span>
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>
            {savedSearches && savedSearches.map(search => (
              <Grid item xs={12} sm={6} md={3} key={search.id}>
                <Paper sx={userviewStyleWithTheme.rowNoCursor} elevation={0} key={`${search.id}_paper`}>
                  <Grid container justifyContent="space-between" alignItems="center" direction="row" key={`${search.id}_cont_1`}>
                    <Grid item xs={12} sx={userviewStyleWithTheme.searchItem} key={`${search.id}_cont_item_1`}>
                      <Icon
                        color="inherit"
                        sx={userviewStyleWithTheme.searchIcon}
                        style={{ color: `#${search.color}` }}
                        key={`${search.id}_icon_1`}
                      >
                        fiber_manual_record_rounded_icon
                      </Icon>
                      <Typography variant="body2" key={`${search.id}_text_1`}>{search.title}</Typography>
                    </Grid>
                    <Grid item xs={12} container key={`${search.id}_cont_2`}>
                      <Grid item xs={5} sx={userviewStyleWithTheme.searchItem} key={`${search.id}_item_2`}>
                        <Icon color="disabled" sx={userviewStyleWithTheme.searchIcon} key={`${search.id}_icon_2`}>schedule_rounded_icon</Icon>
                        <Typography variant="body2" key={`${search.id}_text_2`}>{search.positionType}</Typography>
                      </Grid>
                      <Grid item xs={5} sx={userviewStyleWithTheme.searchItem} key={`${search.id}_item_3`}>
                        <Icon color="disabled" sx={userviewStyleWithTheme.searchIcon} key={`${search.id}_icon_3`}>location_on_rounded_icon</Icon>
                        <Typography variant="body2" sx={userviewStyleWithTheme.searchLocationText} key={`${search.id}_text_3`}>
                          {this.renderLocation(search)}
                        </Typography>
                        {!search.city && !search.state && search.practices && search.practices.length > 1 && (
                          <Badge badgeContent={`+${search.practices.length - 1}`} color="primary" key={`${search.id}_badge`} />
                        )}
                      </Grid>
                      <Grid item xs={2} sx={userviewStyleWithTheme.searchItem} key={`${search.id}_item_4`}>
                        {hasPermission(admin, MANAGE_SAVED_SEARCH_PERMISSION) && (
                          <Button
                            variant="contained"
                            sx={userviewStyleWithTheme.formBtnSmall}
                            onClick={() => this.setState({
                              openSearchDialog: true,
                              savedSearchItem: search,
                              savedSearchId: search.id,
                              enableDelete: true,
                              nextColor: null
                            })}
                          >
                            <span style={{ verticalAlign: 'super' }}>EDIT</span>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      );
  };

  renderPracticeInformation() {
    const {
      classes,
      resources,
      theme,
    } = this.props;
    const practices = (resources && resources.adminPractices) || [];
    const accesses = this.getResourceValue('useraccesses', 'user') || [];
    const accessKeys = accesses.map(access => access.practice_id);
    const filteredPractices = practices.filter(practice => accessKeys.includes(practice.id));
    const practiceNames = filteredPractices.map(practice => practice.name);
    const userviewStyleWithTheme = UserViewStyles(theme);

    return !practiceNames.length
      ? <div />
      : (
        <div>
          <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>Practices</Typography>
          {
            practiceNames.map(name => (
              <Typography key={name} sx={userviewStyleWithTheme.userInfoBody}>
                {`${name}`}
              </Typography>
            ))
          }
        </div>
      );
  }

  renderHomePracticeInformation() {
    const { theme } = this.props;
    const homePractice = this.getHomePractice();
    if (!homePractice) return '';

    const { userInfoTitle } = UserViewStyles(theme);

    return (
      <div>
        <Typography color="secondary" sx={userInfoTitle}>Home Practice</Typography>
        <Typography>
          {homePractice}
        </Typography>
      </div>
    );
  }

  renderFastpassPractices() {
    const {
      classes,
      history,
      match,
      resources,
      actions,
      apiToken,
      theme,
    } = this.props;
    const {
      fastpassData,
      loaded,
      openAddPracticeDialog,
      activePractices,
      deletedRowIds,
    } = this.state;
    const talent = this.getResourceValue('talents', 'user');
    const user = this.getResourceValue('users');
    const userviewStyleWithTheme = UserViewStyles(theme);

    return (
      <div>
        <div className={classes.titleContainer}>
          <Typography color="secondary" sx={userviewStyleWithTheme.userInfoTitle}>Fastpass Practices</Typography>
          <div className={classes.reportButtonGroup}>
            <Button
              variant="contained"
              disableRipple
              onClick={e => this.setState({ openAddPracticeDialog: true })}
              sx={userviewStyleWithTheme.reportButton}
              startIcon={(<AddBoxIcon />)}
              disabled={user ? !user.enabled : false}
            >
              Add practice
            </Button>
          </div>
        </div>
        <div className={classes.dataGridContainer}>
          <DataGrid
            autoHeight
            rows={fastpassData || []}
            columns={[
              {
                field: 'practiceName',
                headerName: 'Practice Name',
                flex: 1,
              }, {
                field: 'action',
                headerName: 'Action',
                flex: 0.25,
                type: 'actions',
                renderCell: params => (
                  <strong>
                    {params?.row?.deletedAt
                      ? (
                        <Button
                          onClick={() => this.handleActionClick({ action: 'add', id: params?.row?.id, practiceId: params?.row?.practice_id })}
                          title='Restore the row'
                        >
                          <AddCircleIcon
                            style={{ color: green }}
                            size="small"
                          />
                        </Button>
                      )
                      : (
                        <Button
                          title='Delete the row'
                          onClick={() => {
                            this.setState({ deletedRowIds: [...deletedRowIds, params.row.id] },
                              () => this.handleActionClick({ action: 'remove', id: params?.row?.id }));
                          }}
                        >
                          <RemoveCircleIcon
                            style={{ color: 'red' }}
                            size="small"
                          />
                        </Button>
                      )
                    }

                  </strong>
                ),
              },
            ]}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            getRowClassName={params => (params?.row?.deletedAt ? classes.disabledRow : '')}
            disableColumnMenu
            isRowSelectable={() => false}
            loading={!loaded}
          />
        </div>
        <AddFastpassPracticeDialog
          open={openAddPracticeDialog}
          apiToken={apiToken}
          handleOnClose={(refreshData) => {
            this.setState({ openAddPracticeDialog: false });
            if (refreshData) {
              this.retrieveFastpassData();
            }
          }}
          actions={actions}
          practices={activePractices}
          talentId={talent?.id}
          fastpassData={fastpassData}
          autoFocus
        />
      </div>
    );
  }

  handleActionClick = ({ action, id, practiceId }) => {
    const { actions, apiToken, match } = this.props;
    const { userId } = match.params;
    const { deletedRowIds, activePractices } = this.state;

    if (action === 'remove') {
      if (!id) return;
      actions.deleteFastpass(apiToken, 'fastpass', id).then((deleteResp) => {
        if (deleteResp?.data) {
          let resultList = deleteResp?.data.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id)));
          resultList = sortBy(resultList, 'createdAt').reverse();
          this.setState({ fastpassData: resultList });
        }
      });
    } else if (action === 'add') {
      if (!userId || !practiceId) return;
      actions.addResource(apiToken, {
        practiceId: Number(practiceId),
        userId,
      }, 'fastpass').then((resp) => {
        if (resp && resp.response) {
          let resultList = resp?.response?.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id)));
          resultList = sortBy(resultList, 'createdAt').reverse();
          this.setState({ fastpassData: resultList });
        }
      });
    }
  };

  renderContent = () => {
    const {
      classes,
      history,
      match,
      theme,
    } = this.props;
    const { userId } = match && match.params;

    const userviewStyleWithTheme = UserViewStyles(theme);

    return (
      <div className={classes.usersViewContainer}>
        {/* <Typography className={classes.pageTitle} color='secondary'>View Private Staff</Typography> */}
        <Paper sx={userviewStyleWithTheme.paper}>
          {this.renderUserBanner()}
          <Grid container sx={userviewStyleWithTheme.body}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} sx={userviewStyleWithTheme.bodyItem}>
                  {this.renderHomePracticeInformation()}
                </Grid>
                <Grid item xs={12} sx={userviewStyleWithTheme.bodyItem}>
                  {this.getConnectionDetails()}
                </Grid>
                <Grid item xs={12} sx={userviewStyleWithTheme.bodyItem}>
                  {this.renderPracticeInformation()}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={userviewStyleWithTheme.bodyItem}>
                {this.renderFastpassPractices()}
              </Grid>
              <Grid item xs={12} md={4} sx={userviewStyleWithTheme.bodyItem}>
                {this.getExperienceDetails()}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {this.renderSavedSearches()}
            </Grid>
            <Grid item xs={12}>
              <hr />
              <Grid container justifyContent="space-between">
                <Grid item xs={3} container alignItems="center">
                  <Icon sx={userviewStyleWithTheme.backIcon} onClick={() => history.goBack()}>arrow_back</Icon>
                  <Typography onClick={() => history.goBack()} color="secondary" sx={userviewStyleWithTheme.backText}>
                    Back to Staff
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={userviewStyleWithTheme.formBtn}
                    onClick={e => history.push(`/users/private/${userId}/edit`)}
                  >
                    <span style={{ verticalAlign: 'super' }}>EDIT</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };

  render() {
    const { loading } = this.props;
    return (
      loading
        ? <div style={{ marginTop: '20px' }}><LoadingBar /></div>
        : (
          this.renderContent()
        )
    );
  }
}

UsersView.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withStyles(UserViewStyles, { withTheme: true })(withRouter(UsersView));
