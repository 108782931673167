import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {isEmpty, uniqBy} from 'lodash';
import {
  Typography,
  IconButton,
  Grid,
  Box, FormControlLabel, AccordionSummary, AccordionDetails, Accordion,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  green,
  navy,
  orange,
  bgGray,
  tiffany,
  orangeAccent, yellowCoronado, errorRed,
  greenBoosted, gray, yellowCoronadoBG, lightGray,
} from '../../css/style';
import {
  IMG_STORAGE_TALENT_LINK,
  IMG_STORAGE_LINK,
  VET_TYPES,
} from '../../data/Constants';
import {getTimeCategory} from '../../utils/Functions';
import PracticeAutocomplete from './PracticeAutocomplete';
import SingleSelect from './SingleSelect';
import GreenSwitch from './GreenSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {formatISODateOnly} from '../Datepicker/dateUtils';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: theme && theme.palette.grey[500],
  },
  tag: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginBottom: 5,
  },
  blockedTag: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
    marginBottom: 5,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tagContainer: {
    fontFamily: 'Asap',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 24,
    padding: '8px 0px',
  },
  submitButtonContainer: {
    width: '30%',
    maxWidth: '100px',
  },
  shiftCalendarTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleTextDiv: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      fontSize: '25px',
      fontWeight: '700',
      color: navy,
      padding: '0px 8px',

      [theme && theme.breakpoints.down('md')]: {
        fontSize: '18px',
        fontWeight: '500',
      },
    },
  },
  shiftCalendarTitle: {
    color: navy,
    padding: '0px 8px',
    fontSize: '20px',
    fontWeight: '700',
  },
  calendarTitle: {
    color: navy,
    fontSize: '16px',
    fontWeight: '600',
  },
  shiftCalendarToolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',

    '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '3px',
    },

    '& .MuiOutlinedInput-input': {
      padding: '9.5px',
    },

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  shiftCalendarMonthBtn: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    cursor: 'pointer!important',
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    color: navy,
    display: 'flex',
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  shiftInfo: {
    fontFamily: 'ASAP',
    fontSize: 12,
    color: 'white',
  },
  fullCalendarContainer: {
    '& .fc-header-toolbar': {
      display: 'none',
    },
    '& .fc-col-header-cell-cushion': {
      fontFamily: 'ASAP',
    },
    '& .fc-daygrid-day-frame': {
      fontFamily: 'ASAP',
    },
    '& .fc-daygrid-day-top': {
      fontFamily: 'ASAP',
      fontSize: '12px',
    },
    '& .fc .fc-toolbar.fc-header-toolbar': {
      color: navy,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: '2',
    },
    '& .fc-toolbar-chunk': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .fc .fc-toolbar-title': {
      color: navy,
      fontSize: '20px',
      fontWeight: '700',
    },
    '& .fc .fc-button-primary': {
      backgroundColor: 'white',
      color: navy,
      borderColor: tiffany,
      borderRadius: '50%',
      padding: '4px',
      fontSize: '10px',
      fontWeight: '700',

      '&:hover': {
        backgroundColor: 'white',
        color: tiffany,
      },
      '&:disabled': {
        backgroundColor: 'white',
        color: tiffany,
      },
    },
    '& .fc td, .fc th': {
      minWidth: '156px',
    },
    '& .fc .fc-daygrid-day-number': {
      fontSize: '20px',
      color: navy,

      [theme && theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    '& .fc .fc-daygrid-day': {
      '&:selected': {
        backgroundColor: tiffany,
      },
    },

    '& .fc .fc-daygrid-day.fc-day-today': {
      backgroundColor: bgGray,
      boxShadow: '0px 0px 0px 3px #A6B0BF inset',
      border: '1px solid #DCE0E5',
      fontWeight: '700',
    },
    '& .fc-daygrid-dot-event': {
      padding: 0,
      borderRadius: '20px',
    },
    '& .fc-daygrid-day-events': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
  },
  error: {
    color: 'red',
    paddingTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerToolbarContainer: {
    backgroundColor: bgGray,
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
  pastDates: {
    '& .fc-daygrid-day-number': {
      width: '100%',
    },
    backgroundColor: lightGray,
  },
  futureCoronadoDates: {
    '& .fc-daygrid-day-number': {
      width: '100%',
    },

    backgroundColor: yellowCoronadoBG,
  },
  originalDay: {
    '& .fc-daygrid-day-number': {
      width: '100%',
    },
  },
  dayHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.9rem',
  },
  boostedDay: {
    color: greenBoosted,
    fontWeight: '700',
    marginRight: '4px',
    marginLeft: '4px',
  },
  sxDay: {
    color: errorRed,
    fontWeight: '700',
    marginRight: '4px',
    marginLeft: '4px',
  },
  parent: {
    display: 'flex',
    flexGrow: 1,
    color: 'white',
    justifyContent: 'center',
    backgroundColor: greenBoosted,
    borderRadius: '12px',
    padding: '4px',
  },
  child: {
    display: 'flex',
    flexGrow: 1,
    color: 'white',
    justifyContent: 'center',
    backgroundColor: yellowCoronado,
    borderRadius: '12px',
    padding: '4px',
  },
  taken: {
    display: 'flex',
    flexGrow: 1,
    color: 'white',
    justifyContent: 'center',
    backgroundColor: navy,
    padding: '4px',
  },
}));

const sxStyles = {
  filterGridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '16px',
  },
  boxIcon: {
    border: `1px solid ${tiffany}`,
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    marginLeft: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventDiv: {
    width: '100%',
    textAlign: 'center',
    padding: '2px 6px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};
const buildEvents = (props, classes) => {
  const events = [];
  const model = {};

  const setEvent = (date, value, type, skipChild, child) => {
    if (!value) return;
    const dateStr = formatISODateOnly(date);
    model[dateStr] = model[dateStr] || [];
    const item = model[dateStr].find(e => e.type === type && (skipChild || e.child === child));
    if (item) item.value += value;
    else {
      const e = {
        date, value, type, child, class: classes[type],
      };
      model[dateStr].push(e);
      events.push(e);
    }
  };

  const loop = (dates, type, child, skipChild) => {
    for (const date of dates || []) {
      setEvent(date, 1, type, skipChild, child);
    }
  };
  const loopOverMulti = (multi, isChild) => {
    for (const key of Object.keys(multi)) {
      const item = multi[key];
      setEvent(item.date, item.available, isChild ? 'child' : 'parent', false, isChild);
      setEvent(item.date, item.taken, 'taken', true, isChild);
      setEvent(item.date, item.pending, 'taken', true, isChild);
    }
  };

  loop(props.availableDates, 'parent', false, false);
  loop(props.takenDates, 'taken', false, true);
  loop(props.pendingDates, 'taken', false, true);
  loop(props.childAvailableDates, 'child', true, false);
  loop(props.childTakenDates, 'taken', true, true);
  loop(props.childPendingDates, 'taken', true, true);
  loopOverMulti(props.multipleNeeds, false);
  loopOverMulti(props.childMultipleNeeds, true);
  return events.sort((a, b) => a.type.localeCompare(b.type));
};

const JobpostingCalendar = (props) => {
  const {
    enabledMultipleNeeds,
    handleEventSelect = () => {
    },
    dateClickCallback = (date, event) => {
    },
    selectedDateCallback = (date) => {

    },
  } = props;
  const classes = useStyles();
  const events = buildEvents(props, classes);
  const [monthTitle, setMonthTitle] = useState('');
  const [initialDate, setInitialDate] = useState(() => new Date());
  const boostedInfo = {};
  for (const boostDetail of props.boostDetails || []) {
    boostedInfo[formatISODateOnly(boostDetail.date)] = boostDetail;
  }
  for (const boostDetail of props.childBoostDetails || []) {
    if (boostedInfo[formatISODateOnly(boostDetail.date)]) {
      boostedInfo[formatISODateOnly(boostDetail.date)].isBoosted = boostedInfo[formatISODateOnly(boostDetail.date)].isBoosted || boostDetail.isBoosted;
      boostedInfo[formatISODateOnly(boostDetail.date)].surgeryNeeded = boostedInfo[formatISODateOnly(boostDetail.date)].surgeryNeeded || boostDetail.surgeryNeeded;
    } else {
      boostedInfo[formatISODateOnly(boostDetail.date)] = boostDetail;
    }
  }

  const fullCalendarRef = useRef(null);

  const renderFullCalendar = () => (
    <div
      style={{ minWidth: 'unset' }}
      className={classes.fullCalendarContainer}
    >
      <FullCalendar
        ref={fullCalendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        initialDate={initialDate}
        weekends
        events={events}
        eventContent={eventInfo => renderEventContent(eventInfo)}
        headerToolbar={{ left: '', center: '', right: '' }}
        viewDidMount={(info) => {
          setMonthTitle(info?.view?.title);
        }}
        dateClick={e => dateClickCallback(e?.date)}
        eventClick={e => dateClickCallback(e?.event?.start, e?.event?.extendedProps)}
        dayCellClassNames={(dateInfo) => {
          const today = new Date();
          const date = dateInfo.date;
          if (date < today) {
            return classes.pastDates;
          } else if (props.showCoronado && (date - today) / (1000 * 60 * 60 * 24) > 60) {
            return classes.futureCoronadoDates;
          }
          return classes.originalDay;
        }}
        dayCellContent={arg => (
          <div className={classes.dayHeader}>
            <span>{arg.dayNumberText}</span>
            <span>
              {boostedInfo[formatISODateOnly(arg.date)]?.isBoosted
                && <span className={classes.boostedDay}>$</span> || ''}
              {boostedInfo[formatISODateOnly(arg.date)]?.surgeryNeeded
                && <span className={classes.sxDay}>Sx</span> || ''}
            </span>
          </div>
        )}
        eventLimit
        views={{
          dayGridMonth: {
            dayMaxEventRows: 3,
          },
        }}
        editable={false}
        titleFormat={{ year: 'numeric', month: 'short' }}
        fixedWeekCount={false}
        showNonCurrentDates={false}
      />
    </div>
  );

  const renderEventContent = (eventInfo) => {
    const eventData = eventInfo?.event || {};
    const extendedProps = eventData?.extendedProps || {};
    return <div className={extendedProps.class}>{extendedProps.value}</div>;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.headerToolbarContainer}>
        <Grid container className={classes.shiftCalendarTitleContainer}>
          <Grid item xs={4} className={classes.shiftCalendarToolbar}>
            <div className={classes.titleTextDiv}>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton
                  onClick={() => {
                    fullCalendarRef?.current?.getApi()?.prev();
                    setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                    selectedDateCallback(fullCalendarRef?.current?.getApi()?.currentData?.currentDate);
                  }}
                  size='small'
                >
                  <ChevronLeftIcon style={{ fontSize: '16px' }}/>
                </IconButton>
              </Box>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton
                  onClick={() => {
                    fullCalendarRef?.current?.getApi()?.next();
                    setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                    selectedDateCallback(fullCalendarRef?.current?.getApi()?.currentData?.currentDate);
                  }}
                  size='small'
                >
                  <ChevronRightIcon style={{ fontSize: '16px' }}/>
                </IconButton>
              </Box>
              <Typography className={classes.shiftCalendarTitle}>
                {monthTitle}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8} className={classes.tagContainer}>
            <div className={classes.item}>
              <div className={classes.tag} style={{ color: greenBoosted, fontSize: '0.9rem', fontWeight: 600 }}>$</div>
              <span>Boosted</span>
            </div>
            <div className={classes.item}>
              <div className={classes.tag} style={{ color: errorRed, fontSize: '0.9rem', fontWeight: 600 }}>Sx</div>
              <span>Surgery Needed</span>
            </div>
            <div className={classes.item}>
              <div className={classes.tag} style={{ backgroundColor: green }}/>
              <span>Available All</span>
            </div>
            {props.showCoronado && (<div className={classes.item}>
              <div className={classes.tag} style={{ backgroundColor: yellowCoronado }}/>
              <span>Available Coronado ER</span>
            </div>)}
            <div className={classes.item}>
              <div className={classes.blockedTag} style={{ backgroundColor: navy }}/>
              <span>Taken</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>{renderFullCalendar()}</div>
    </div>
  );
};

export default JobpostingCalendar;
